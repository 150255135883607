@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;500;600&display=swap');

body {
  background-color: black;
  color: white;
  font-family: 'Roboto', sans-serif;
  min-width: 250px;
}

.container {
  text-align: center;
  padding: 20px;
  max-width: 100%;
  box-sizing: border-box;
}

h1 {
  font-family: 'Oswald', sans-serif;
  font-size: 2.5em;
  margin: 0;
  color: white;
  text-shadow: 
    0 0 10px #A25CCB,
    0 0 20px #A25CCB,
    0 0 30px #A25CCB;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.profile-section {
  margin-bottom: 40px;
}

.profile-pic {
  border-radius: 50%;
  width: 20vw;
  max-width: 150px;
  margin: 20px;
  height: auto;
  border: 3px solid #000000;
  animation: glow 1.5s infinite alternate;
}

.social-icon {
  font-size: 1rem;
  padding: 4px;
}

.social-icon-link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 30px;
  border: 2px solid #8a2be2;
  border-radius: 5px;
  background-color: transparent;
  color: #8a2be2;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.social-icon-link:hover {
  background-color: rgba(138, 43, 226, 0.3);
  color: #fff;
}

.social-links {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.album-section {
  margin-top: 40px;
}

.album-art {
  width: 50%;
  max-width: 300px;
  height: auto;
  animation: glow 1.5s infinite alternate;
  border: 2px solid #8a2be2;
  border-radius: 10px;
  padding: 5px;
}

.content-section {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.button {
  font-size: 24px;
  margin: 0 10px;
  padding: 10px;
  height: 50px;
  border: 2px solid #8a2be2;
  border-radius: 5px;
  background-color: transparent;
  color: #8a2be2;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.button:hover {
  background-color: rgba(138, 43, 226, 0.3);
  color: #fff;
}

.content {
  flex: 1;
  max-width: 600px;
  padding: 20px;
  text-align: center;
  font-size: 1.2em;
  border: 2px solid #8a2be2;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.7);
}

.title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.5em;
  margin: 0;
}

.icon-container {
  display: flex;
  justify-content: center;
  gap: 16px;
}

.icon-link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 50px;
  border: 2px solid #8a2be2;
  border-radius: 5px;
  background-color: transparent;
  color: #8a2be2;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.icon-link:hover:not(.disabled) {
  background-color: rgba(138, 43, 226, 0.3);
  color: #fff;
}

.icon-link.disabled {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
}

.icon {
  font-size: 24px;
}

.hidden-button {
  border: none;
  background: none;
  padding: 0;
  width: 100%;
  height: auto;
  display: inline-block;
}

.popup-overlay {
  min-width: 320px;
  position: fixed;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 1);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 1000;
  padding-top: 10vh;
  animation: fadeIn 0.5s ease-in-out;
}

.popup-content {
  min-width: 320px;
  background: black;
  color: white;
  border-radius: 8px;
  position: relative;
  width: 80%;
  max-width: 600px;
  max-height: 60vh;
  overflow-y: auto;
  z-index: 2000;
}

.fade-out {
  position: absolute;
  bottom: 31.5vh;
  left: 0;
  right: 0;
  height: 10vh;
  background: linear-gradient(to bottom, transparent, black);
  z-index: 3000;
}

.popup-overlay h2 {
  font-family: 'Oswald', sans-serif;
  font-size: 1.5em;
  margin: 0;
  color: white;
  text-shadow: 
    0 0 10px #ff0000,
    0 0 20px #ff0000,
    0 0 30px #ff0000;
  position: sticky;
  top: 0;
  z-index: 2010;
  padding: 2vh 0;
}

.popup-story {
  padding: 10px;
}

.red-text {
  color: #ff0000;
  font-weight: bold;
}

.gap {
  height: 5vh;
}

.popup-content:before,
.popup-content:after {
  content: '';
  position: fixed;
  border-radius: 50%;
  background: rgba(255, 0, 0, 0.7);
  z-index: -1;
  animation: eye-glow 1.5s infinite alternate;
  width: 120px;
  height: 80px;
}

.popup-content:before {
  top: 50%;
  left: calc(50% - 100px);
  transform: translate(-50%, -50%);
}

.popup-content:after {
  top: 50%;
  left: calc(50% + 100px);
  transform: translate(-50%, -50%);
}

.popup-content::-webkit-scrollbar {
  width: 10px;
}

.popup-content::-webkit-scrollbar-track {
  background: rgba(255, 0, 0, 0.1);
  border-radius: 10px;
}

.popup-content::-webkit-scrollbar-thumb {
  background: #ff0000;
  border-radius: 10px;
}

.popup-content::-webkit-scrollbar-thumb:hover {
  background: #ff4d4d;
}

.popup-content {
  scrollbar-width: thin;
  scrollbar-color: #ff0000 rgba(255, 0, 0, 0.1);
}


@keyframes eye-glow {
  0% {
    box-shadow: 0 0 20px rgba(255, 0, 0, 0.7), 0 0 30px rgba(255, 0, 0, 0.4);
  }
  100% {
    box-shadow: 0 0 40px rgba(255, 0, 0, 1), 0 0 60px rgba(255, 0, 0, 0.4);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.popup-content p {
  text-align: justify;
  margin: 10px;
}

.close-popup {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
}

@keyframes glow {
  0% {
    box-shadow: 0 0 10px purple, 0 0 15px purple, 0 0 20px purple, 0 0 25px purple;
  }
  50% {
    box-shadow: 0 0 15px purple, 0 0 25px purple, 0 0 30px purple, 0 0 35px purple;
  }
  100% {
    box-shadow: 0 0 10px purple, 0 0 15px purple, 0 0 20px purple, 0 0 25px purple;
  }
}

@media (max-width: 768px) {
  .profile-pic {
    width: 30vw;
    max-width: 100px;
  }

  body {
    font-size: 0.9em;
  }

  .popup-overlay{
    padding-top: 5vh;
  }

  .popup-overlay h2 {
    font-size: 1em;
  }

  .popup-content{
    max-height: 70vh;
  }

  .popup-content:before,
  .popup-content:after {
    width: 100px;
    height: 66px;
  }

  .popup-content:before {
    left: calc(50% - 80px);
  }

  .popup-content:after {
    left: calc(50% + 80px);
  }

  .fade-out{
    bottom: 23vh;
  }
}